@import "definitions/colors";

/*RESPONSIVE VARIABLES*/
/*defined in breakpoints.scss*/

/* CONTAINER*/
$container-max-width: 3200px;
$container-secondary-max-width: 960px;

/* BORDER*/
$border-color: $brand-grey-dark;
$border-radius: 6px;
$border-radius--wide: 40px;

/* BOX SHADOW*/
$box-shadow-color: rgba($black, .1);
$box-shadow-size: 3.2px 7.2px;
$box-shadow--wide-size: 40px;
$box-shadow: 0 3.2px 7.2px 0 $box-shadow-color;
$box-shadow--wide: 0 0 $box-shadow--wide-size 0 $box-shadow-color;

/* FONT*/
$font-family: 'Montserrat', sans-serif;
$font-family-secondary: 'Amatic SC', cursive;
$font-size: 16px;
$font-size-secondary: 26px;
$font-weight: 300;

/* BODY*/
$body-color: $black;
$body-color--secondary: black;
$body-bg: $brand-pale;

/* SPACING*/
$padding-x: 30px;
$padding-y: 30px;

/* FORM*/
$input-border-radius: calc(#{$border-radius--wide} / 3);
$button-height: 50px;

/* Footer Header*/
$footerHeight:50px;
$headerHeight:70px;



