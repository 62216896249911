@import "../variables";

h1, h2, h3, h4 {
  font-family: $font-family-secondary;
}

h1 {
  font-size: 48px;
  margin: 0 0 10px;
}

h2 {
  font-size: 38px;
  margin: 0 0 10px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
  color: $body-color--secondary;
}
