@import "../variables";

form {
  input:not(.mat-input-element), textarea {
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    padding: 10px 15px;
    overflow: hidden;
    font-family: $font-family;
    font-size: $font-size;

    &:focus {
      outline: none;
      box-shadow: 0 0 5px rgba($accent, 0.5);
      border: 1px solid $accent;
    }
  }

  label {
    color: $body-color;

    &.error {
      input {
        border-color: rgba($danger, 0.5);
        box-shadow: 0 0 5px rgba($danger, 0.5);
      }
    }

    &.success {
      input {
        border-color: rgba($success, 0.5);
        box-shadow: 0 0 5px rgba($success, 0.5);
      }
    }
  }
}

button, input[type="submit"] {
  border: none;
  box-shadow: none;
  background: none;
  cursor: pointer;
  font: inherit;

  &:focus {
    outline: none;
  }
}
