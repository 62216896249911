@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Montserrat:wght@300;400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "../node_modules/leaflet/dist/leaflet.css";

@import "styles/elements/a";
@import "styles/elements/main";
@import "styles/elements/body";
@import "styles/elements/form";
@import "styles/elements/html";
@import "styles/elements/headings";
@import "styles/elements/p";

@import "styles/overrides/mat-menu";
@import "styles/overrides/smap";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
