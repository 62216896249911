@import "../variables";

a {
  text-decoration: none;
  cursor: pointer;
  color: $accent;

  &:hover {
    text-decoration: none;
  }
}
