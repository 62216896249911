.mat-menu {
  &-content {
    display: flex;
    flex-direction: column;

    &:not(:empty) {
      padding: 0 !important;
    }

    button {
      line-height: 50px;
      text-align: left;
      padding: 0 15px;
      min-width: 180px;

      &:hover {
        background: #eeeeee;
      }
    }
  }

  &-panel {
    margin-top: 15px;
    border-radius: 0 !important;
    min-height: 0 !important;
  }
}
